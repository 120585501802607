<template>
  <Layout>
    <PageHeader :title="title" :items="items"></PageHeader>
    <div class="row" id="article">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center mb-4">
              <div class="col-md-6">
                <div class="form-inline navbar-search">
                  <div class="input-group">
                    <input
                      name="title"
                      class="form-control bg-light border-0 small"
                      placeholder="Search Comment"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      value=""
                      v-model="filter"
                      v-on:keyup="search"
                    />
                    <div class="input-group-append">
                      <button
                        id="myBtn"
                        class="btn btn-search"
                        @click="searchFor"
                      >
                        <i class="fas fa-search fa-sm"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive mb-0">
              <b-table
                striped
                bordered
                :items="tableData.data"
                :fields="fields"
                :sort-by.sync="sortBy"
                :per-page="0"
                :current-page="currentPage"
                :sort-desc.sync="sortDesc"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                responsive="sm"
                class="table-bordered table-hover"
              >
                <template
                  v-can="'update-feedback-status'"
                  v-slot:cell(reported_spam)="row"
                >
                  <b-form-checkbox
                    switch
                    class="mb-1"
                    @change="updateStatus(row.item.id)"
                    :checked="row.item.reported_spam == 0 ? true : false"
                  >
                  </b-form-checkbox>
                </template>
                <template v-slot:cell(question)="row">
                  <span v-if="row.item.question" class="handle-width">{{
                    row.item.question
                  }}</span>
                  <span v-else>---</span>
                </template>
                <template v-slot:cell(reference_name)="row">
                  <span v-if="row.item.reference_name" class="handle-width">{{
                    row.item.reference_name
                  }}</span>
                  <span v-else>---</span>
                </template>
                <template v-slot:cell(reference_type)="row">
                  <div class="handle-width">
                    <span
                      v-if="row.item.reference_type"
                      class="badge badge-primary"
                      style="text-transform: capitalize"
                      >{{ row.item.reference_type }}</span
                    >
                    <span v-else>---</span>
                  </div>
                </template>
              </b-table>
            </div>
            <template v-if="tableData.total_pages > 1">
              <div class="data_paginate">
                <div
                  class="dataTables_paginate paging_simple_numbers d-flex justify-content-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination
                      v-model="currentPage"
                      first-number
                      last-number
                      :total-rows="tableData.total"
                      :total-pages="tableData.total_pages"
                      :per-page="tableData.per_page"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import CommentMixin from "../../../mixins/ModuleJs/comment";

export default {
  mixins: [MixinRequest, CommentMixin],
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Comments",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "List",
        },
      ],
    };
  },
};
</script>
